<template>
  <div class="blue-skin">
    <div id="main-wrapper">
      <app-header style="border-bottom: solid 1px #ddd" />

      <section>
        <div class="container">
          <!-- row Start -->
          <div class="row">
            <div class="col-lg-7 col-md-7">
              <form @submit.prevent="send" v-if="! loading">
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label>Name</label>
                      <input type="text" class="form-control simple" v-model="message.name" required placeholder="Your Name" />
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label>Email</label>
                      <input type="email" class="form-control simple" v-model="message.email" required placeholder="Email" />
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Subject</label>
                  <input type="text" class="form-control simple" v-model="message.subject" required placeholder="Subject" />
                </div>

                <div class="form-group">
                  <label>Message</label>
                  <textarea class="form-control simple" v-model="message.message" placeholder="Message"></textarea>
                </div>

                <div class="form-group">
                  <button class="btn btn-theme-light-2 rounded" type="submit">
                    Submit Request
                  </button>
                </div>
              </form>
              <loading v-if="loading" message="Sending Message" />
            </div>

            <div class="col-lg-5 col-md-5">
              <div class="contact-info">
                <h2>Get In Touch</h2>
                <p class="mb-4">
                  Use the form or the information below.
                </p>

                <div class="cn-info-detail">
                  <div class="cn-info-icon">
                    <i class="ti-home"></i>
                  </div>
                  <div class="cn-info-content">
                    <h4 class="cn-info-title">Reach Us</h4>
                    {{ contact.location }}
                  </div>
                </div>

                <div class="cn-info-detail">
                  <div class="cn-info-icon">
                    <i class="ti-email"></i>
                  </div>
                  <div class="cn-info-content">
                    <h4 class="cn-info-title">Drop A Mail</h4>
                    {{ contact.email }}
                  </div>
                </div>

                <div class="cn-info-detail">
                  <div class="cn-info-icon">
                    <i class="ti-mobile"></i>
                  </div>
                  <div class="cn-info-content">
                    <h4 class="cn-info-title">Call Us</h4>
                    {{ contact.phone_number }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /row -->
        </div>
      </section>

      <section class="theme-bg call-to-act-wrap">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="call-to-act">
                <div class="call-to-act-head">
                  <h3>Post your property on RealHub</h3>
                  <span
                    >We'll help you get to potential clients much faster.</span
                  >
                </div>
                <router-link
                  :to="{ name: 'submit-property' }"
                  class="btn btn-call-to-act"
                  >Submit Property</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </section>

      <app-footer />
    </div>
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import AppFooter from "./components/AppFooter.vue";

export default {
  components: {
    AppHeader,
    AppFooter,
  },

  mounted() {
    document.title = 'Contact RealHub Kenya - Your Premier Source for Properties in Kenya - Get in Touch Today'
  },

  data() {
    return {
      message: {},
      loading: false
    }
  },

  methods: {
    send() {
      this.loading = true

      this.$axios.post('/api/v1/contact-us', this.message).then(() => {
        this.loading = false
        this.message = {}
        this.$swal('Your message has been received.')
      })
    }
  },

  computed: {
    contact() {
      return  this.$store.getters.getContact
    }
  }
};
</script>
